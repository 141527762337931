import {
  applyEdgeChanges,
  applyNodeChanges,
  Controls,
  Edge,
  EdgeChange,
  EdgeMarker,
  getIncomers,
  getOutgoers,
  Node,
  NodeChange,
  ReactFlow,
  useNodesInitialized,
  useReactFlow,
} from '@xyflow/react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

// eslint-disable-next-line import/no-internal-modules
import '@xyflow/react/dist/style.css';

import { darkPalette } from '@src/theme/color-palette';

import { Button } from '../Button/Button';
import { FlowDiagramProps } from './FlowDiagram';
import { getLayoutedElements, isEdgeRelatedToNode } from './utils';

const CustomControls = styled(Controls)`
  .react-flow__controls-button {
    background: ${({ theme }) => theme.color.baseLayer};
    border-bottom: 1px solid ${({ theme }) => theme.color.baseOutline};
    fill: ${({ theme }) => theme.color.text};
  }
`;

const GraphButton = styled(Button)`
  z-index: 10;
  position: relative;
`;
const ReactFlowInternal = <NodeData extends Record<string, unknown>>({
  nodes: initialNodes,
  edges: initialEdges,
  showControls,
  nodeTypes,
  onNodeClick,
  onPaneClick,
}: FlowDiagramProps<NodeData>) => {
  // State
  const [nodes, setNodes] = useState<Node<NodeData>[]>(initialNodes);
  const [edges, setEdges] = useState(initialEdges);
  const [selectedNode, setSelectedNode] = useState<Node>();
  const [isFocusedView, setIsFocusedView] = useState<boolean>(false);
  const [dagreLayoutChanged, setDagreLayoutChanged] = useState<boolean>();
  const [initialZoomLevel, setInitialZoomLevel] = useState<number>(0.8);

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');

  const { getNodes, getEdges, setCenter, fitView, getZoom } = useReactFlow<Node<NodeData>>();
  const nodesInitialized = useNodesInitialized();

  const setGraphLayout = useCallback((nodesToLayout: Node<NodeData>[], edgesToLayout: Edge[]) => {
    const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements<NodeData>(
      nodesToLayout,
      edgesToLayout
    );
    setNodes(layoutedNodes);
    setEdges(layoutedEdges);
    setDagreLayoutChanged(true);
  }, []);

  useEffect(() => {
    if (nodesInitialized) {
      setGraphLayout(getNodes(), getEdges());
    }
  }, [nodesInitialized, getNodes, getEdges, setGraphLayout]);

  useEffect(() => {
    // fit the graph in the viewport after it has been initialised
    if (dagreLayoutChanged) {
      fitView({ minZoom: 0.6, maxZoom: 1, duration: 800 });
      setDagreLayoutChanged(false);
      if (!initialZoomLevel) {
        setInitialZoomLevel(getZoom());
      }
    }
  }, [fitView, dagreLayoutChanged, getZoom, initialZoomLevel]);

  const onNodesChange = useCallback(
    (changes: NodeChange<Node<NodeData>>[]) =>
      setNodes((nds) => applyNodeChanges<Node<NodeData>>(changes, nds)),
    []
  );
  const onEdgesChange = useCallback(
    (changes: EdgeChange[]) => setEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  /**
   * Toggle highlighting the nodes and edges connected to a specific node
   *
   * @param node
   * @param removeHighlight if this is true, the highlighting will be removed
   */
  const toggleHighlight = (node: Node, removeHighlight?: boolean) => {
    if (!removeHighlight) {
      setSelectedNode(node);
    }
    if (node && nodes && edges) {
      if (node.measured?.width && node.measured?.height) {
        if (removeHighlight) {
          setTimeout(() => {
            fitView({ minZoom: 0.6, maxZoom: 1, duration: 1000 });
          }, 50);
        } else {
          // move viewport to the selected node
          setCenter(node.position.x + node.measured.width / 2, node.position.y, {
            zoom: initialZoomLevel + 0.05,
            duration: 1000,
          });
        }
      }
      setNodes((prevNodes) => {
        return prevNodes?.map((currentNode) => {
          const highlight = currentNode.id === node.id && !removeHighlight;
          currentNode = {
            ...currentNode,
            data: {
              ...currentNode.data,
              selected: highlight,
            },
            style: {
              ...currentNode.style,
              opacity: highlight || selectedNode ? 1 : 0.25,
            },
          };
          return currentNode;
        });
      });
      setEdges((prevEdges) => {
        return prevEdges?.map((currentEdge) => {
          const highlight =
            isEdgeRelatedToNode(currentEdge, node, nodes, edges) && !removeHighlight;
          currentEdge = {
            ...currentEdge,
            markerEnd: {
              ...(currentEdge.markerEnd as EdgeMarker),
              color: highlight ? darkPalette.main : undefined,
            },
            style: {
              ...currentEdge.style,
              stroke: removeHighlight
                ? undefined
                : highlight
                  ? darkPalette.main
                  : darkPalette.baseTransparent,
            },
            animated: highlight,
          };
          return currentEdge;
        });
      });
    }
  };

  const focusSelection = () => {
    if (selectedNode) {
      setIsFocusedView(true);
      const incomerIds = getIncomers(selectedNode, nodes, edges).map((node) => node.id);
      const outgoerIds = getOutgoers(selectedNode, nodes, edges).map((node) => node.id);
      const relatedNodes = initialNodes?.filter(
        (node) =>
          node.id === selectedNode.id ||
          incomerIds.includes(node.id) ||
          outgoerIds.includes(node.id)
      );
      const relatedEdges = initialEdges?.filter((edge) =>
        isEdgeRelatedToNode(edge, selectedNode, nodes, edges)
      );
      setGraphLayout(relatedNodes, relatedEdges);
      toggleHighlight(selectedNode);
    }
  };

  const handleBackToFullGraphClick = () => {
    setIsFocusedView(false);
    setSelectedNode(undefined);
    setGraphLayout(initialNodes, initialEdges);
    if (onPaneClick) {
      onPaneClick();
    }
  };

  return (
    <ReactFlow<Node<NodeData>>
      nodes={nodes}
      edges={edges}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeTypes={nodeTypes}
      maxZoom={2}
      minZoom={0.6}
      nodesDraggable={false}
      proOptions={{
        hideAttribution: true,
      }}
      onNodeClick={(e, node) => {
        toggleHighlight(node);
        if (onNodeClick) {
          onNodeClick(node);
        }
      }}
      onPaneClick={() => {
        if (selectedNode && !isFocusedView) {
          toggleHighlight(selectedNode, true);
          setSelectedNode(undefined);
          if (onPaneClick) {
            onPaneClick();
          }
        }
      }}
      defaultViewport={{ x: 0, y: 0, zoom: initialZoomLevel }}>
      {!isFocusedView ? (
        <GraphButton
          iconLeft={'arrow-circle'}
          variant={'secondary'}
          className={'mt-lg ml-lg'}
          disabled={!selectedNode}
          onClick={focusSelection}>
          {uiTranslations.FOCUS_SELECTION}
        </GraphButton>
      ) : (
        <GraphButton
          iconLeft={'arrow-left'}
          variant={'secondary'}
          className={'mt-lg ml-lg'}
          onClick={handleBackToFullGraphClick}>
          {uiTranslations.BACK_TO_FULL_GRAPH}
        </GraphButton>
      )}
      {showControls && <CustomControls showInteractive={false} />}
    </ReactFlow>
  );
};

export default ReactFlowInternal;
