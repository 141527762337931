import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';

import { MatchParams } from '@src/models/routing';
import makeRequest from '@src/utilities/make-request';

import { environmentQueryKeys } from '../environmentQueryKeys';

const useEnvironmentPauseStatusQuery = (
  customParams?: { orgId?: string; appId?: string },
  enabled = true
) => {
  const { orgId: routerOrgId, appId: routerAppId } = useParams<keyof MatchParams>() as MatchParams;

  const orgId = customParams?.orgId || routerOrgId;
  const appId = customParams?.appId || routerAppId;

  return useQuery({
    queryKey: environmentQueryKeys.environmentPauseStatus(orgId, appId),
    queryFn: () =>
      makeRequest<{ id: string; paused: boolean }[]>(
        'GET',
        `/orgs/${orgId}/apps/${appId}/runtime/paused`
      ),
    enabled: Boolean(orgId && appId && enabled),
    select: (response) =>
      response?.data.reduce<Record<string, boolean>>((acc, { id, paused }) => {
        acc[id] = paused;
        return acc;
      }, {}),
  });
};

export default useEnvironmentPauseStatusQuery;
