import { Button, EmptyStateCard } from '@humanitec/ui-components';
import { useEffect } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import useResourceClassesQuery from '@src/hooks/react-query/resources/queries/useResourceClassesQuery';
import { CoProvisionResource, CoProvisionResourceFields } from '@src/models/resources';

import CoProvisionResourceCard from './CoProvisionResourceCard';

const Section = styled.div`
  border-top: 1px solid ${({ theme }) => theme.color.baseOutline};
  color: ${({ theme }) => theme.color.textTranslucent};
`;

type FormValues = {
  coprovisionResources: CoProvisionResourceFields[];
};

export interface CoProvisioningSectionProps {
  coprovisionedResources?: Record<string, CoProvisionResource>;
  viewMode?: boolean;
}

/*
 * This contains the section handling provisioning of resource.
 */
const CoProvisioningSection = ({
  coprovisionedResources,
  viewMode,
}: CoProvisioningSectionProps) => {
  // i18n
  const { t } = useTranslation();
  const translations = t('ACCOUNT_SETTINGS').RESOURCES.COPROVISION;

  // React query
  const { data: resourceClasses } = useResourceClassesQuery();

  // form
  const { control, formState } = useFormContext<FormValues, 'coprovisionResources'>();
  const {
    fields: coprovisionResourceFieldsArray,
    append,
    remove,
    replace,
  } = useFieldArray({
    // read more at https://react-hook-form.com/docs/usefieldarray
    name: 'coprovisionResources',
    control,
    rules: {
      validate: (_, values) => {
        // add validation rules here
        const descriptorNames = values.coprovisionResources?.map(
          (item) => item.resource_descriptor
        );
        const uniqueDescriptorNames = new Set();
        const duplicateDescriptorNames: string[] = [];

        descriptorNames?.forEach((name) => {
          if (name) {
            if (!uniqueDescriptorNames.has(name)) {
              uniqueDescriptorNames.add(name);
            } else if (!duplicateDescriptorNames.includes(name)) {
              duplicateDescriptorNames.push(name);
            }
          }
        });
        // return true if there are no duplicate names otherwise it will trigger form error
        return duplicateDescriptorNames.length === 0 || duplicateDescriptorNames.join(' ,');
      },
    },
  });

  useEffect(() => {
    replace(
      Object.entries(coprovisionedResources || {}).map(([descriptor, coprovisionResource]) => {
        return {
          resource_type: descriptor.substring(
            0,
            descriptor.indexOf('.') !== -1
              ? descriptor.indexOf('.')
              : descriptor.indexOf('#') !== -1
                ? descriptor.indexOf('#')
                : undefined
          ),
          resource_id:
            descriptor.indexOf('#') !== -1 ? descriptor.substring(descriptor.indexOf('#') + 1) : '',
          resource_class:
            descriptor.indexOf('.') !== -1
              ? descriptor.substring(
                  descriptor.indexOf('.') + 1,
                  descriptor.indexOf('#') !== -1 ? descriptor.indexOf('#') : undefined
                )
              : undefined,
          resource_descriptor: descriptor,
          match_dependents: coprovisionResource.match_dependents,
          is_dependent: coprovisionResource.is_dependent,
        };
      })
    );
  }, [coprovisionedResources, replace]);

  const addResource = () => {
    append({
      resource_type: '',
      resource_id: '',
      resource_class: '',
      resource_descriptor: '',
      match_dependents: false,
      is_dependent: false,
    });
  };

  const handleDelete = (index: number) => {
    remove(index);
  };
  return (
    <Section className={'mt-lg mb-lg'}>
      <div className={'mt-lg mb-md txt-lg'}>{translations.TITLE}</div>
      <div className={'mb-md txt-sm'}>
        <Trans defaults={translations.DESCRIPTION}>
          <Link
            target={'_blank'}
            to={
              'https://developer.humanitec.com/platform-orchestrator/resources/resource-graph/#co-provision-resources'
            }
          />
        </Trans>
      </div>
      {Object.keys(coprovisionedResources || {}).length === 0 && (
        <EmptyStateCard className={'mb-md'}>
          {translations.NO_COPROVISIONED_RESOURCES}
        </EmptyStateCard>
      )}
      {coprovisionResourceFieldsArray.map((coprovisionResourceCard, index) => (
        <CoProvisionResourceCard
          key={coprovisionResourceCard.id}
          id={coprovisionResourceCard.id}
          coprovisionResourceFields={coprovisionResourceCard}
          index={index}
          resourceClasses={resourceClasses}
          onDeleteButtonClick={() => handleDelete(index)}
          viewMode={viewMode}
          error={Boolean(
            coprovisionResourceCard.resource_descriptor &&
              formState.errors?.coprovisionResources?.root?.message?.includes(
                coprovisionResourceCard.resource_descriptor
              )
          )}
        />
      ))}
      {!viewMode && (
        <Button className={'mt-md'} onClick={addResource} variant={'secondary'} iconLeft={'plus'}>
          {coprovisionResourceFieldsArray.length === 0
            ? translations.ADD_RESOURCE
            : translations.ADD_ANOTHER_RESOURCE}
        </Button>
      )}
    </Section>
  );
};

export default CoProvisioningSection;
