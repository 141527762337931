import {
  Button,
  DropdownItem,
  ErrorWarning,
  FormGenerator,
  Icon,
  Tooltip,
  WalDropdownMenu,
  WalInput,
  WalLabel,
  WarningSection,
} from '@humanitec/ui-components';
import { MutableRefObject, useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import DynamicForm from '@src/components/shared/DynamicForm/DynamicForm';
import { FieldProps } from '@src/components/shared/DynamicForm/utils/dynamic-form-types';
import {
  isPatternProperties,
  replaceKeyDelimiter,
} from '@src/components/shared/DynamicForm/utils/dynamic-form-utils';
import { getResourceAccountTypeText } from '@src/containers/Orgs/Accounts/Accounts';
import AddAccountModal from '@src/containers/Orgs/Accounts/components/AddAccountModal/AddAccountModal';
import {
  buildCoProvisionResourcesPayload,
  getDriverSchema,
  makeResourceSpecificModificationstoDynamicFormValues,
} from '@src/containers/Orgs/Resources/components/ResourceDefinitionForm/resource-form.utils';
import useResourceAccountsQuery from '@src/hooks/react-query/resource-accounts/queries/useResourceAccountsQuery';
import useResourceDefinitionCreateMutation from '@src/hooks/react-query/resources/mutations/useResourceDefinitionCreateMutation';
import useResourceDefinitionUpdateMutation from '@src/hooks/react-query/resources/mutations/useResourceDefinitionUpdateMutation';
import useResourceDefinitionsQuery from '@src/hooks/react-query/resources/queries/useResourceDefinitionsQuery';
import { useResourcesPlaceholders } from '@src/hooks/useResourcesPlaceholders';
import { useResourcesStore } from '@src/hooks/zustand/useResourcesStore';
import { ResourceAccount, ResourceAccountType } from '@src/models/resource-account';
import {
  ResourceDefinition,
  ResourceDriver,
  ResourceForm,
  ResourceType,
} from '@src/models/resources';
import { ellipsisStyle } from '@src/styles/mixins';
import { units } from '@src/styles/variables';
import { DynamicFormSchema } from '@src/types/dynamic-form';
import { getChangedFormFields } from '@src/utilities/form';
import { convertToNameAndId } from '@src/utilities/string-utility';

import CoProvisioningSection from '../CoProvisioningSection/CoProvisioningSection';

const ButtonWrapper = styled.div`
  display: flex;
  bottom: 0;
  flex: 1;
  padding: ${units.padding.lg} 0;
`;

const CreateButton = styled(Button)`
  margin-right: ${units.margin.sm};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;

const MainInputsWrapper = styled.div`
  display: grid;
  column-gap: ${units.margin.xs};
  margin: ${units.margin.sm} 0;
  align-items: center;
`;

const DriverForm = styled(FormGenerator)`
  margin: ${units.margin.sm} 0;
`;

const AccountItemWrapper = styled.div`
  display: flex;
`;

const AccountDataWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: ${units.margin.md};
`;

const AccountName = styled.span`
  color: ${({ theme }) => theme.color.text};
  font-size: ${units.fontSize.base};
`;

const StyledTooltip = styled(Tooltip)`
  p {
    ${ellipsisStyle(200)}
  }
`;

const DynamicFormSection = styled(DynamicForm)`
  margin-top: ${units.margin.md};
`;

export interface ResourceDefinitionFormProps {
  resourceType: ResourceType;
  onCanceButtonClicked?: () => void;
  /** If present, resource is being edited */
  resource?: ResourceDefinition;
  selectedDriver?: ResourceDriver;
  /** If the form should be shown in view mode **/
  viewMode?: boolean;
  /** this helps us to submit the form from a parent compoennt */
  createButtonRef?: MutableRefObject<HTMLButtonElement | null>;
  onResourceDefinitionCreatedOrUpdated?: () => void;
  onResourceDefinitionCreating?: (creating: boolean) => void;
}

const ResourceDefinitionForm = ({
  resourceType,
  selectedDriver,
  resource,
  onCanceButtonClicked,
  viewMode,
  createButtonRef,
  onResourceDefinitionCreatedOrUpdated,
  onResourceDefinitionCreating,
}: ResourceDefinitionFormProps) => {
  // Component state
  const [formSchema, setFormSchema] = useState<DynamicFormSchema>();
  const [driverAccountItems, setDriverAccountItems] =
    useState<DropdownItem<ResourceAccount | string>[]>();
  const [addAccountModalOpen, setAddAccountModalOpen] = useState<boolean>(false);
  const [addAccountType, setAddAccountType] = useState<ResourceAccountType>('gcp');
  const [propertiesToParse, setPropertiesToParse] = useState<Set<string>>(new Set<string>());
  /**
   * Any dynamic form with patternProperties will result in an array with entries containing 'key', 'value'. The backend does not expect array format, so we need to convert to an object. This state specifies the paths to such patternProperties.
   */
  const [patternPropertyPaths, setPatternPropertyPaths] = useState<Set<string>>(new Set<string>());
  const placeholders = useResourcesPlaceholders();

  const errorWarningRef = useRef<HTMLDivElement>(null);

  // Form
  const {
    handleSubmit,
    reset,
    setValue,
    setError,
    getValues,
    formState: { dirtyFields, errors },
    register,
  } = useFormContext<ResourceForm>();

  // Zustand
  const { pushIdToLastCreatedResourceDefinitions, lastCreatedResourceAccount } =
    useResourcesStore();

  // React query
  const {
    mutate: createResourceDefinition,
    isPending: isCreating,
    error: createError,
  } = useResourceDefinitionCreateMutation(
    (error) => {
      if (error?.response?.status === 409) {
        setError('id', { type: 'manual', message: resourcesTranslations.ID_EXISTS });
      }
      if (onResourceDefinitionCreating) {
        onResourceDefinitionCreating(false);
      }
      // scroll to the error warning, so it becomes visible to the user. this doesn't work without a setTimeout
      // I think because it needs to wait until the element is available in the DOM.
      setTimeout(() => {
        errorWarningRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 30);
    },
    (data) => {
      if (onResourceDefinitionCreatedOrUpdated) {
        onResourceDefinitionCreatedOrUpdated();
      }
      if (onResourceDefinitionCreating) {
        onResourceDefinitionCreating(false);
      }
      pushIdToLastCreatedResourceDefinitions(data.id);
    }
  );
  const {
    mutate: updateResourceDefinition,
    isPending: isUpdating,
    error: updateError,
  } = useResourceDefinitionUpdateMutation(
    () => {
      if (onResourceDefinitionCreatedOrUpdated) {
        onResourceDefinitionCreatedOrUpdated();
      }
    },
    () => {
      // scroll to the error warning, so it becomes visible to the user. this doesn't work without a setTimeout
      // I think because it needs to wait until the element is available in the DOM.
      setTimeout(() => {
        errorWarningRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }, 10);
    }
  );
  const { data: resourceDefinitions } = useResourceDefinitionsQuery();
  const { data: resourceAccounts } = useResourceAccountsQuery();

  // i18n
  const { t } = useTranslation();
  const resourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES;
  const coprovisionResourcesTranslations = t('ACCOUNT_SETTINGS').RESOURCES.COPROVISION;

  const uiTranslations = t('UI');

  useEffect(() => {
    if (lastCreatedResourceAccount) {
      setAddAccountModalOpen(false);
      setValue('driver_account', lastCreatedResourceAccount.id);
    }
  }, [lastCreatedResourceAccount, setValue]);

  useEffect(() => {
    const { schema } = getDriverSchema({
      resource,
      driver: selectedDriver,
      resourceType,
    });

    setFormSchema(schema);
  }, [resourceType, resource, selectedDriver, setValue]);

  useEffect(() => {
    // set the available account options depending on the driver account_types field
    if (selectedDriver?.account_types) {
      const availableAccounts =
        resourceAccounts?.filter((resourceAccount) =>
          selectedDriver?.account_types.includes(resourceAccount.type)
        ) ?? [];
      setDriverAccountItems([
        {
          id: 'no-account',
          value: 'no-account',
          label: resourcesTranslations.NO_ACCOUNT,
        },
        {
          separatorItem: true,
          id: 'existing-account',
          value: 'separator',
          label: resourcesTranslations.USE_EXISTING_ACCOUNT,
          hideFromList: availableAccounts?.length === 0,
        },
        ...availableAccounts.map((resourceAccount) => {
          const accountType =
            resourceAccount.type === 'aws-role'
              ? 'aws'
              : resourceAccount.type === 'gcp-identity'
                ? 'gcp'
                : resourceAccount.type === 'azure-identity'
                  ? 'azure'
                  : resourceAccount.type;
          return {
            id: resourceAccount.id,
            label: getResourceAccountTypeText(accountType, resourcesTranslations),
            value: resourceAccount,
            component: (
              <AccountItemWrapper>
                <Icon name={accountType} size={32} />
                <AccountDataWrapper>
                  <AccountName>{resourceAccount.name}</AccountName>
                  <WalLabel>
                    {getResourceAccountTypeText(accountType, resourcesTranslations)}
                  </WalLabel>
                </AccountDataWrapper>
              </AccountItemWrapper>
            ),
          };
        }),
        {
          separatorItem: true,
          id: 'add-new-account',
          value: 'separator',
          label: resourcesTranslations.ADD_NEW_ACCOUNT,
        },
        ...selectedDriver?.account_types?.map((accountType) => ({
          id: accountType,
          notSelectable: true,
          value: 'new-account',
          label: getResourceAccountTypeText(accountType, resourcesTranslations),
          component: (
            <div className={'flex-centered'}>
              <Icon
                size={20}
                name={
                  accountType === 'aws-role'
                    ? 'aws'
                    : accountType === 'gcp-identity'
                      ? 'gcp'
                      : accountType === 'azure-identity'
                        ? 'azure'
                        : accountType
                }
                marginRight={'md'}
              />
              {getResourceAccountTypeText(accountType, resourcesTranslations)}
            </div>
          ),
        })),
      ]);
    }
  }, [resourceAccounts, resourcesTranslations, selectedDriver, setValue]);

  /**
   * Reset the driver form when the driver is changed. Maintain id
   * */
  useEffect(() => {
    reset({ id: getValues().id });
  }, [selectedDriver, reset, getValues]);

  const handleAccountsDropdownItemClick = (
    id: string,
    item: DropdownItem<ResourceAccount | string>
  ) => {
    if (item.value === 'new-account') {
      setAddAccountModalOpen(true);
      setAddAccountType(id as ResourceAccountType);
    } else if (item.value === 'no-account') {
      setValue('driver_account', '', { shouldDirty: true });
    } else {
      setValue('driver_account', item.id, { shouldDirty: true });
    }
  };

  const submitForm = (formValues: ResourceForm) => {
    const { id, driver_account } = formValues;

    let nameAndId;
    if (!resource && id) {
      nameAndId = convertToNameAndId(id);
    }

    formValues = makeResourceSpecificModificationstoDynamicFormValues({
      formValues,
      dirtyFields,
      propertiesToParse,
      patternPropertyPaths,
      resource,
    });

    if (resourceType) {
      let payload: Partial<ResourceDefinition> = {
        id: resource ? resource.id : nameAndId?.id,
        driver_account: driver_account === 'no-account' ? undefined : driver_account,
      };

      if (!resource) {
        payload = {
          ...payload,
          driver_inputs: formValues.driver_data,
          type: resourceType?.type,
          driver_type: `${selectedDriver?.org_id}/${selectedDriver?.id}`,
          name: nameAndId?.name,
          provision: formValues.coprovisionResources
            ? buildCoProvisionResourcesPayload(formValues.coprovisionResources)
            : undefined,
        };
        createResourceDefinition({ resourceDefinition: replaceKeyDelimiter(payload) });
        if (onResourceDefinitionCreating) {
          onResourceDefinitionCreating(true);
        }
      } else {
        const changedValues = getChangedFormFields(formValues, dirtyFields);
        const coProvisionResources =
          changedValues.coprovisionResources || formValues.coprovisionResources;
        // Ensure that the provision payload is sent, even if unchanged.
        // Omitting it results in unintended deletion of coprovision resources when modifying other fields.
        if (coProvisionResources?.length) {
          payload.provision = buildCoProvisionResourcesPayload(coProvisionResources);
        }
        if (changedValues.driver_data) {
          payload.driver_inputs = changedValues.driver_data;
        }
        updateResourceDefinition({ resourceDefinition: replaceKeyDelimiter(payload) });
      }
    }
  };

  const onFieldCallback = ({ schema, path }: FieldProps) => {
    if (isPatternProperties(schema)) {
      setPatternPropertyPaths((prevState) => {
        if (!prevState.has(path)) {
          prevState.add(path);
        }
        return prevState;
      });
    }
    if (schema?.type === 'object') {
      setPropertiesToParse((prevState) => {
        if (!prevState.has(path)) {
          prevState.add(path);
        }
        return prevState;
      });
    }
  };

  const currentAccount = resource?.driver_account
    ? driverAccountItems?.find((accountItem) => accountItem.id === resource?.driver_account)?.id
    : lastCreatedResourceAccount
      ? driverAccountItems?.find((accountItem) => accountItem.id === lastCreatedResourceAccount.id)
          ?.id
      : driverAccountItems?.[0]?.id;
  return (
    <Wrapper>
      <form onSubmit={handleSubmit(submitForm)}>
        {/* Hidden input to store the org_id of the selected driver */}
        <input type={'hidden'} {...register('org_id')} />
        {resource ? (
          <MainInputsWrapper>
            <FieldWrapper>
              <WalLabel>{resourcesTranslations.ID}</WalLabel>
              {resource?.id?.length > 30 ? (
                <StyledTooltip
                  disableTooltip={resource?.id?.length <= 30}
                  text={resource?.id.replace(/(.{1,50})/g, '$1\u00ad')}
                  triggerComponent={<p>{resource?.id}</p>}
                  position={'bottom'}
                />
              ) : (
                <span>{resource?.id}</span>
              )}
            </FieldWrapper>
            <FieldWrapper>
              <WalLabel>{resourcesTranslations.DRIVER}</WalLabel>
              <span>{resource.driver_type}</span>
            </FieldWrapper>
          </MainInputsWrapper>
        ) : (
          <DriverForm
            fields={[
              {
                type: 'input',
                props: {
                  name: 'id',
                  label: resourcesTranslations.ID,
                  required: true,
                  standardValidation: [
                    { type: 'id' },
                    { type: 'existingId', ids: resourceDefinitions?.map((rd) => rd.id) || [] },
                  ],
                },
              },
            ]}
          />
        )}

        {driverAccountItems &&
          selectedDriver &&
          selectedDriver?.account_types.length > 0 &&
          (!viewMode ? (
            <WalDropdownMenu
              name={'driver_account'}
              label={resourcesTranslations.CREDENTIALS}
              items={driverAccountItems}
              defaultValue={currentAccount}
              buttonVariant={'input'}
              fullWidth
              overflowScrollable
              maxHeight={200}
              onItemClick={handleAccountsDropdownItemClick}
            />
          ) : (
            <WalInput
              name={'account'}
              viewMode={viewMode}
              label={resourcesTranslations.CREDENTIALS}
              defaultValue={currentAccount}
            />
          ))}
        {formSchema && (
          <DynamicFormSection
            key={selectedDriver?.id}
            formSchema={formSchema}
            prefix={'driver_data'}
            editing={Boolean(resource)}
            onFieldCallback={onFieldCallback}
            viewMode={viewMode}
            autoCompleteOptions={{
              lookupObject: placeholders,
              customLanguage: 'resources',
            }}
          />
        )}
        <CoProvisioningSection coprovisionedResources={resource?.provision} viewMode={viewMode} />
        {!viewMode && (updateError || createError) && (
          <ErrorWarning
            className={'mt-md'}
            code={
              updateError ? updateError.response?.data.error : createError?.response?.data.error
            }
            message={
              updateError ? updateError.response?.data.message : createError?.response?.data.message
            }
            details={
              updateError
                ? JSON.stringify(updateError.response?.data.details, undefined, 2)
                : createError
                  ? JSON.stringify(createError.response?.data.details, undefined, 2)
                  : undefined
            }
            ref={errorWarningRef}
          />
        )}
        {errors.coprovisionResources?.root?.message && (
          <WarningSection mode={'alert'}>
            <Trans
              defaults={coprovisionResourcesTranslations.DUPLICATE_DESCRIPTOR_ERROR}
              values={{ descriptorNames: errors.coprovisionResources.root?.message }}
            />
          </WarningSection>
        )}
        {!viewMode && resource && (
          <ButtonWrapper>
            <CreateButton
              disabled={!Boolean(Object.keys(dirtyFields).length)}
              type={'submit'}
              loading={isUpdating || isCreating}>
              {uiTranslations.SAVE}
            </CreateButton>
            <Button
              variant={'secondary'}
              onClick={() => onCanceButtonClicked && onCanceButtonClicked()}>
              {uiTranslations.CANCEL}
            </Button>
          </ButtonWrapper>
        )}
        <button ref={createButtonRef} type={'submit'} hidden />
      </form>

      <AddAccountModal
        openState={[addAccountModalOpen, setAddAccountModalOpen]}
        accountType={addAccountType}
      />
    </Wrapper>
  );
};

export default ResourceDefinitionForm;
