import {
  ActionButtons,
  Button,
  ConfirmationDialog,
  EmptyStateCard,
  Icon,
  SearchInput,
  SecretValue,
  VariablesViewAddEditProps,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import SharedAppValuesHistory from '@src/components/shared/SharedAppValuesHistory/SharedAppValuesHistory';
import SharedValueExpandableContent from '@src/components/shared/SharedValuesTable/SharedValueExpandableContent';
import {
  OutdatedEnvs,
  OverridenValue,
  StyledDash,
} from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/AppValues';
import useSecretStoresQuery from '@src/hooks/react-query/secret-stores/queries/useSecretStoresQuery';
import { useDecision } from '@src/hooks/useDecision';
import { useGetUserRoles } from '@src/hooks/useGetUserRoles';
import { AppValue } from '@src/models/app-value';
import { units } from '@src/styles/variables';

const ActionsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  align-items: center;
`;

const ResetButtonWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const FilterInput = styled(SearchInput)`
  align-self: flex-end;
  margin-bottom: ${units.margin.md};
`;

interface SharedValuesTableProps {
  values: AppValue[];
  caption: string;
  outdatedValues?: string[];
  overridenValues?: OverridenValue[];
  isEnvValues?: boolean;
  onResetConfirmation?: (key: string) => void;
  onDelete?: (value: AppValue) => void;
  onUpdate?: (updatedValue: AppValue, oldValue: AppValue) => void;
  valuesFetched?: boolean;
  outdatedEnvs?: OutdatedEnvs;
}
const SharedValuesTable = ({
  values,
  valuesFetched,
  caption,
  outdatedValues,
  overridenValues,
  isEnvValues,
  onResetConfirmation,
  outdatedEnvs,
  onDelete,
  onUpdate,
}: SharedValuesTableProps) => {
  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  const settingsTranslations = t('APP_SETTINGS');

  // Context
  const { orgRole, appRole } = useGetUserRoles();

  // state
  const [hideResetConfirmationDialog, setHideResetConfirmationDialog] = useState<boolean>(true);
  const [resetConfirmationDialogIndexToShow, setResetConfirmationDialogIndexToShow] = useState<
    string | undefined
  >();
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyViewKey, setHistoryViewKey] = useState<string>('');
  const [expandedRow, setExpandedRow] = useState<number | undefined>();
  const [filteredValues, setFilteredValues] = useState<AppValue[]>();
  const [rowToOverrideColumns, setRowToOverrideColumns] = useState<number | undefined>();

  // react query
  const { data: secretStores } = useSecretStoresQuery();

  // optimizely
  const [secretReferenceDecision] = useDecision('secret_references');

  useEffect(() => {
    if (values.length > 0) {
      setFilteredValues(values);
    }
  }, [values]);

  /**
   * RBAC
   * This restricts editing/deleting permissions based on RBAC detailed at
   * https://www.notion.so/humanitec/Frontend-Engineering-f8a268f74f7e4a48a3f5f8eea4d94040#6ef33f52f696404593bb7d54c1a2b5cc
   */
  const canEdit = orgRole === 'administrator' || appRole === 'developer' || appRole === 'owner';

  const handleResetConfirmationAction = (key: string) => {
    if (onResetConfirmation) {
      onResetConfirmation(key);
    }
  };

  const handleDelete = (value: AppValue) => {
    if (onDelete) {
      onDelete(value);
    }
  };

  const handleUpdateValue = (updatedValue: AppValue, oldValue: AppValue) => {
    if (onUpdate) {
      onUpdate(updatedValue, oldValue);
    }
  };

  const handleEdit = (index: number) => {
    setExpandedRow(index);
    setRowToOverrideColumns(index);
  };

  const handleShowHistory =
    (row: WalTableRow<AppValue & { path: string }>): MouseEventHandler =>
    (e) => {
      setShowHistoryModal(true);
      setHistoryViewKey(row.data.key);
      e.stopPropagation();
    };

  const handleCancelEditMode = () => {
    setRowToOverrideColumns(undefined);
  };

  const columns: WalTableColumn<AppValue & { path: string }>[] = [
    {
      label: uiTranslations.KEY_LABEL,
      prop: 'key',
      ellipsisTooltip: {
        maxWidth: 150,
        maxCharacters: 20,
        text: (row) => row.data.key.replace(/([_.-])/g, '$1\u200b'),
      },
    },
    {
      label: uiTranslations.VALUE_REFERENCE,
      prop: 'value',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 10,
        text: (row) =>
          row.data.is_secret
            ? row.data.secret_store_id !== 'humanitec'
              ? settingsTranslations.EXTERNAL_SECRET_STORE_REFERENCE_NOTE
              : settingsTranslations.HUMANITEC_SECRET_STORE_NOTE
            : row.data.value,
      },
      template: (row) =>
        row.data.is_secret ? (
          <SecretValue
            secretVersion={row.data.secret_version}
            secretKey={row.data.secret_store_id !== 'humanitec' ? row.data.secret_key : undefined}
          />
        ) : (
          <p>{row.data?.value || <StyledDash>-</StyledDash>}</p>
        ),
    },
    {
      label: uiTranslations.DESCRIPTION,
      prop: 'description',
      ellipsisTooltip: {
        maxWidth: 200,
        maxCharacters: 30,
        text: (row) => row.data.description,
      },
      template: (row) => <p>{row.data?.description || <StyledDash>-</StyledDash>}</p>,
    },
    {
      label: isEnvValues ? settingsTranslations.HAS_OVERRIDES : settingsTranslations.OVERRIDES,
      prop: 'overrides',
      template: (row) =>
        isEnvValues ? (
          <p>{row.data.source === 'env' ? <Icon name={'checkmark'} /> : <span />}</p>
        ) : (
          <span>
            {overridenValues?.filter((value) => value.key === row.data.key).length || '-'}
          </span>
        ),
    },
    {
      prop: 'warning',
      fixedWidth: 180,
      template: (row) =>
        outdatedValues?.includes(row.data.key) && (
          <>
            <Icon name={'info'} className={'mr-md'} />
            {settingsTranslations.NOT_DEPLOYED}
          </>
        ),
    },
    {
      prop: 'actions',
      tabIndex: -1,
      fixedWidth: 130,
      template: (row) => (
        <ActionsWrapper>
          {canEdit && isEnvValues && (
            <ResetButtonWrapper className={'mr-md'}>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  setResetConfirmationDialogIndexToShow(row.data.key);
                  setHideResetConfirmationDialog(false);
                }}
                variant={'secondary'}
                size={'small'}
                ariaLabel={`${settingsTranslations.REMOVE_OVERRIDE} ${row?.data?.key}`}
                disabled={row.data.source !== 'env'}>
                {uiTranslations.RESET}{' '}
              </Button>
              {!hideResetConfirmationDialog &&
                resetConfirmationDialogIndexToShow === row.data.key && (
                  <ConfirmationDialog
                    key={row.data.key}
                    onConfirm={() => handleResetConfirmationAction(row.data.key)}
                    hiddenState={[hideResetConfirmationDialog, setHideResetConfirmationDialog]}
                    confirmationMessage={settingsTranslations.RESET_CONFIRMATION_TEXT}
                    confirmationButtonText={uiTranslations.RESET}
                  />
                )}
            </ResetButtonWrapper>
          )}
          <ActionButtons
            buttons={
              canEdit
                ? isEnvValues
                  ? ['edit', 'history']
                  : ['edit', 'history', 'delete']
                : ['history']
            }
            onEdit={() => handleEdit(values.indexOf(row.data))}
            onDelete={() => handleDelete(row.data)}
            onShowHistory={handleShowHistory(row)}
          />
        </ActionsWrapper>
      ),
    },
  ];

  const filterValues = (value: string) => {
    setFilteredValues(
      values.filter(
        (appValue) =>
          appValue.key.includes(value) ||
          appValue.value?.includes(value) ||
          appValue.description?.includes(value)
      )
    );
  };

  const renderVariablesViewAddEdit = ({
    id,
    keyValue,
    onSave,
    onCancel,
    mode,
    isSaving,
    disableDescriptionField,
    isValueFieldRequired,
    isSecretReference,
  }: VariablesViewAddEditProps) => (
    <SharedValueExpandableContent
      key={keyValue?.key}
      id={id}
      keyValue={keyValue}
      onSave={onSave}
      onCancel={onCancel}
      mode={mode}
      isSaving={isSaving}
      disableDescriptionField={disableDescriptionField}
      isSecretReference={isSecretReference}
      secretStores={secretStores}
      disableKeyField
      showDescription
      outdatedEnvs={outdatedEnvs}
      overridenValues={overridenValues}
      isValueFieldRequired={isValueFieldRequired}
      isEnvValues={isEnvValues}
    />
  );

  return (
    <>
      {valuesFetched && values.length === 0 ? (
        <EmptyStateCard>{settingsTranslations.NO_VALUES_OR_SECRETS}</EmptyStateCard>
      ) : (
        <div className={'flex-column'}>
          <FilterInput
            name={'filter-values'}
            onChange={filterValues}
            placeholder={settingsTranslations.VALUES_FILTER_INPUT_PLACEHOLDER}
          />
          <WalTable
            caption={caption}
            columns={columns}
            expandedRowState={[expandedRow, setExpandedRow]}
            disableScrolling
            tableStyle={'expandable'}
            rows={
              filteredValues
                ? filteredValues?.map((appValue, rowIndex) => ({
                    data: appValue,
                    expandableContent: renderVariablesViewAddEdit({
                      keyValue: appValue,
                      mode: 'view',
                      isSecretReference:
                        secretReferenceDecision.enabled &&
                        Boolean(
                          appValue.secret_store_id && appValue.secret_store_id !== 'humanitec'
                        ),
                    }),
                    overrideColumns: rowToOverrideColumns === rowIndex,
                    columnOverrideTemplate: renderVariablesViewAddEdit({
                      keyValue: appValue,
                      mode: 'edit',
                      onSave: (newValue) => handleUpdateValue(newValue, appValue),
                      onCancel: handleCancelEditMode,
                      disableKeyField: true,
                      isValueFieldRequired: appValue.is_secret && appValue.source === 'app',
                      secretStores,
                      isSecretReference:
                        secretReferenceDecision.enabled &&
                        Boolean(
                          appValue.secret_store_id && appValue.secret_store_id !== 'humanitec'
                        ),
                    }),
                  }))
                : []
            }
          />
        </div>
      )}
      {showHistoryModal && (
        <SharedAppValuesHistory
          keyValue={historyViewKey}
          onClose={() => setShowHistoryModal(false)}
          isEnvironmentOverride={isEnvValues}
        />
      )}
    </>
  );
};

export default SharedValuesTable;
