import { rgba } from 'polished';
import { ColorPalette } from 'styled-components';
// Main
const mainTransparent = rgba('#1383f1', 0.16);
// Alert
const alertBrightest = '#FF4970';
const alertTransparent = rgba('#F30136', 0.17);
// Other colors
const yellowTransparent = rgba(241, 194, 27, 0.05);

export const darkPalette: ColorPalette = {
  white: '#FFF',
  black: '#000',
  navBgColor: rgba('#000', 0.4),
  // Base
  baseDarker: '#101114',
  base: '#17181C',
  baseTransparent: rgba('#242628', 0.5),
  baseBrighter: '#24252B',
  baseBrightest: '#0b0b0f',
  baseOutline: '#414450',
  baseLayer: '#0D0D0D',
  baseLayerTransparent: rgba('#0D0D0D', 0.5), // previously baseOverlayTransparent
  baseDeployButton: '#425566',
  baseShadow: rgba(18, 22, 25, 0.15), // all shadows color dark theme
  baseSecondaryButton: '#3C3E42',
  // Main
  mainDarker: '#0049BD',
  main: '#0062FF',
  mainBrighter: '#428BFF',
  mainTransparent,
  mainBrighterTransparent: rgba('#428BFF', 0.16),
  mainTransparentSolid: '#28374C',
  // Alert
  alert: '#E80000',
  alertBrighter: '#FF6B6B',
  alertDarker: '#B20000',
  alertBrightest,
  alertTransparent,
  alertInput: '#48222D',
  // Text
  text: '#FAFAFA',
  textTranslucent: '#BDBDBD',
  // Other
  green: '#50D37D',
  yellow: '#F1C21B',
  yellowTransparent,
  // Outline
  outline: '#FFF',
  // Placeholders
  placeholderText: '#D48FFF',
  placeholderBackground: '#2F004D',
  // Date Picker
  datePickerColorScheme: 'dark',
  tableRowHover: '#2156F60D',
  categoricalPalette: {
    purple: '#8a3ffc',
    cyan: '#33b1ff',
    teal: '#007d79',
    magenta: '#ff7eb6',
    red: '#fa4d56',
  },
};

export const lightPalette: ColorPalette = {
  white: '#FFFFFF',
  black: '#000000',
  navBgColor: rgba('#FFFFFF', 0.8),
  // Base
  baseDarker: '#EFF1F7',
  base: '#FCFCFF',
  baseTransparent: rgba('#D7D8DB', 0.22),
  baseBrighter: '#EFEFF5',
  baseBrightest: '#DFE2EB',
  baseOutline: '#ABAFBA',
  baseLayer: '#FFFFFF',
  baseLayerTransparent: rgba('#F2F2F2', 0.99), // previously baseOverlayTransparent
  baseDeployButton: '#AEC3D9',
  baseShadow: rgba(23, 50, 77, 0.08), // all shadows color bright theme
  baseSecondaryButton: '#FFF',
  // Main
  mainDarker: '#0045B2',
  main: '#2156F6',
  mainBrighter: '#3381FF',
  mainBrighterTransparent: rgba('#3381FF', 0.16),
  mainTransparent,
  mainTransparentSolid: '#b8cae1',
  // Danger
  alert: '#D10F29',
  alertBrighter: '#FF1232',
  alertDarker: '#9E0B1F',
  alertBrightest,
  alertTransparent,
  alertInput: rgba('#F30136', 0.16),
  // Text
  text: '#030303',
  textTranslucent: '#545454',
  green: '#228644',
  yellow: '#CF750D',
  yellowTransparent,
  // Outline
  outline: '#0045B2',
  // Placeholders
  placeholderText: '#8500D6',
  placeholderBackground: '#F5E5FF',
  // Date Picker
  datePickerColorScheme: 'light',
  tableRowHover: '#2156F60D',
  categoricalPalette: {
    purple: '#6929c4',
    cyan: '#1192e8',
    teal: '#005d5d',
    magenta: '#9f1853',
    red: '#fa4d56',
  },
};
