import { WalCard } from '@humanitec/ui-components';
import { Handle, Position } from '@xyflow/react';
import styled, { CategoricalPalette } from 'styled-components';

import { ResourceNodeData } from '@src/containers/Orgs/Apps/containers/App/containers/DeploymentAndDeltaCommon/components/ViewDeploymentOrDeltaTabs/components/ResourceDependencyGraph/ResourceDependencyGraph';
import { getResourceSection } from '@src/containers/Orgs/Resources/components/modals/components/SelectResourceTypeStep';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import { ResourceCategory, ResourceTypes } from '@src/models/resources';
import { units } from '@src/styles/variables';

interface ResourceNodeProps {
  data: ResourceNodeData & {
    selected: boolean;
    provisionTime: string;
  };
}

const CustomCard = styled(WalCard)`
  .react-flow__handle {
    opacity: 0;
    pointer-events: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  margin-bottom: ${units.margin.xs};
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: ${units.margin.md};
  align-items: center;
`;

const IconWrapper = styled.div<{ type: ResourceTypes; category?: ResourceCategory }>`
  display: flex;
  background-color: ${({ theme }) => theme.color.text};
  border-radius: 4px;
  background-color: ${({ theme, type, category }) =>
    theme.color.categoricalPalette[getResourceNodeColor(type, category)]};
  padding: ${units.padding.xs};
`;

const getResourceNodeColor = (
  type: ResourceTypes,
  category?: ResourceCategory
): keyof CategoricalPalette => {
  if (type === 'workload') {
    return 'purple';
  } else if (category) {
    if (getResourceSection(category) === 'cluster') {
      return 'cyan';
    } else if (getResourceSection(category) === 'datastore') {
      return 'teal';
    } else if (getResourceSection(category) === 'routing') {
      return 'red';
    } else {
      return 'magenta';
    }
  } else return 'teal';
};
const ResourceNode = ({ data }: ResourceNodeProps) => {
  return (
    <CustomCard
      id={data.res_id}
      dataTestId={data.res_id}
      highlightOnHover
      selected={data.selected}
      className={'nodrag'}>
      <Handle type={'target'} position={Position.Left} />
      <Wrapper>
        <TitleWrapper>
          <IconWrapper type={data.type} category={data.category} data-testid={'icon-wrapper'}>
            <ResourceIcon type={data.type} size={30} overrideColor={'base'} />
          </IconWrapper>
          <div>
            <Title data-testid={'active-resource-card-type'}>{data.type}</Title>
            <span data-testid={'active-resource-card-title'}>{data.res_id}</span>
          </div>
        </TitleWrapper>
      </Wrapper>
      <Handle type={'source'} position={Position.Right} />
    </CustomCard>
  );
};

export default ResourceNode;
