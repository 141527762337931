import { IconNames } from '@humanitec/ui-components';
import { rem } from 'polished';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import Freetrial from '@src/components/shared/Freetrial';
import { windowEnv } from '@src/environment';
import { useDecision } from '@src/hooks/useDecision';
import { AuthenticateFailedReasons } from '@src/hooks/zustand/useCurrentUserStore';
import { units } from '@src/styles/variables';

import { AuthButton, AuthLogo, ButtonText } from './components/ButtonStyles';
import GoogleButton from './components/GoogleButton';

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const GitHubLogo = styled(AuthLogo)`
  path {
    fill: black;
  }
`;

const Warning = styled.span`
  font-size: ${units.fontSize.sm};
  color: ${({ theme }) => theme.color.alertBrighter};
  margin-bottom: ${units.margin.md};
  max-width: ${rem(320)};
  text-align: center;
`;

export interface AuthButtonsProps {
  type: 'login' | 'register';
  googleAuthSuccess: (token: string) => void;
  isInvite: boolean;
  googleOnClick?: () => void;
  githubOnClick?: () => void;
  authenticateFailedReason?: AuthenticateFailedReasons;
  checked?: boolean;
  isMvp?: boolean;
}

const AuthButtons = ({
  type = 'login',
  checked,
  authenticateFailedReason,
  googleAuthSuccess,
  googleOnClick,
  githubOnClick,
  isInvite,
  isMvp,
}: AuthButtonsProps) => {
  // i18n
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  const [ssoButtons] = useState<
    {
      icon: string;
      text: string;
      url: string;
      name?: IconNames;
    }[]
  >(() => {
    try {
      return windowEnv.SSO_BUTTONS ? JSON.parse(windowEnv.SSO_BUTTONS) : [];
    } catch {
      return [];
    }
  });

  const [githubUserScopeDecision] = useDecision('github-user-scope');

  const githubRedirect = () => {
    if (githubOnClick) {
      githubOnClick();
    }

    let redirectUrl = `https://github.com/login/oauth/authorize?allow_signup=true&client_id=${windowEnv.OAUTH_GITHUB_CLIENT_ID}&scope=user:email${githubUserScopeDecision.enabled ? '%20read:user' : ''}`;

    const loginType = `/github-${type}${isInvite ? '-invite' : isMvp ? '-mvp' : ''}${
      windowEnv.ENVIRONMENT_NAME === 'local' ? '-local' : ''
    }`;

    redirectUrl += `&redirect_uri=${windowEnv.BASE_URL.replace('api', 'app')}/auth${loginType}`;

    if (type === 'login' || checked) {
      window.location.assign(redirectUrl);
    }
  };

  const ssoRedirect = (url: string | URL) => {
    const jsURL = new URL(url);
    const search = jsURL.search;

    const newURL = `${jsURL.origin}${jsURL.pathname}${search}${search ? '&' : '?'}redirect=${
      window.location.href
    }`;
    if (type === 'login' || checked) {
      window.location.assign(newURL);
    }
  };

  return (
    <ButtonWrapper>
      <AuthButton
        type={'submit'}
        onClick={githubRedirect}
        size={'large'}
        className={`qa-github-${type === 'login' ? 'login' : 'signup'}-button`}>
        <GitHubLogo size={20} name={'github'} disableInvert />
        <ButtonText>
          {type === 'login' ? authTranslations.LOG_IN : authTranslations.SIGN_UP}{' '}
          {authTranslations.WITH_GITHUB}
        </ButtonText>
      </AuthButton>
      {authenticateFailedReason === 'google-not-exist' && (
        <Warning>
          {authTranslations.GOOGLE_ACCOUNT_DOESNT_EXIST}
          <Freetrial />
        </Warning>
      )}

      <GoogleButton
        googleAuthSuccess={googleAuthSuccess}
        googleOnClick={googleOnClick}
        checked={checked}
        type={type}
      />

      {ssoButtons.map((ssoButton) => (
        <AuthButton key={ssoButton.url} size={'large'} onClick={() => ssoRedirect(ssoButton.url)}>
          <AuthLogo
            size={20}
            url={
              ssoButton.name ? undefined : { src: ssoButton.icon, alt: authTranslations.SSO_ICON }
            }
            name={ssoButton.name}
          />
          <ButtonText>{ssoButton.text}</ButtonText>
        </AuthButton>
      ))}
    </ButtonWrapper>
  );
};

export default AuthButtons;
