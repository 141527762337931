import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { useDecision } from '@src/hooks/useDecision';

const Freetrial = () => {
  const { t } = useTranslation();
  const authTranslations = t('AUTHENTICATE');

  const [signUpLinkDecision] = useDecision('sign-up-link');

  return (
    <Link
      className={'txt-sm'}
      to={signUpLinkDecision.enabled ? '/auth/signup' : authTranslations.FREE_TRIAL_LINK}>
      {authTranslations.SIGN_UP}
    </Link>
  );
};

export default Freetrial;
