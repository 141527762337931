import { EmptyStateCard, SearchInput, WalTable } from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import useApplicationsQuery from '@src/hooks/react-query/applications/queries/useApplicationsListQuery';
import useUserDetailQuery from '@src/hooks/react-query/user/useUserDetailQuery';
import useUserRolesQuery from '@src/hooks/react-query/user/useUserRolesQuery';
import useFilterApplications from '@src/hooks/useFilterApplications';
import { AppRoles } from '@src/models/role';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateAppURL } from '@src/utilities/navigation';
import { capitalize } from '@src/utilities/string-utility';

import AdministratorWarning from '../../../../components/AdministratorWarning';
import ChangeApplicationRoleModal from '../../../../components/role-modals/ChangeApplicationRoleModal/ChangeApplicationRoleModal';

const RoleGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  grid-gap: ${units.padding.xl};
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const OrgMemberApplicationRoles = () => {
  // Component state
  const [filterValue, setFilterValue] = useState<string>();
  // React Query
  const { data: userRoles } = useUserRolesQuery();

  // i18n
  const { t } = useTranslation('orgMembers');
  const translations = t('APPLICATIONS_TAB');

  // React Router
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // React Query
  const { data: applications = [] } = useApplicationsQuery();
  const { data: user } = useUserDetailQuery();

  const { filteredApplications } = useFilterApplications(filterValue);

  return (
    <>
      <AdministratorWarning type={'application'} />
      <SearchInput
        className={'my-md'}
        name={'filter-applications'}
        onChange={setFilterValue}
        placeholder={translations.FIND_APPLICATION}
      />
      {(!applications.length || !filteredApplications.length) && (
        <EmptyStateCard>
          {filterValue && applications.length
            ? 'No applications matching these filters found'
            : 'No applications to show'}
        </EmptyStateCard>
      )}
      <WalTable
        caption={t('APPLICATION_ROLES')}
        columns={[
          {
            prop: 'application',
            label: t('APPLICATIONS_TITLE', { returnObjects: false }),
            template: (row) => {
              return (
                <Link className={'txt-base'} to={`${generateAppURL(orgId, row.data.id)}/people`}>
                  {row.data.name}
                </Link>
              );
            },
          },
          {
            prop: 'role',
            label: translations.APP_PERMISSIONS,
            fixedWidth: 200,
            template: (row) => {
              const role = userRoles?.[`/orgs/${orgId}/apps/${row.data.id}`] as
                | AppRoles
                | undefined;

              return (
                <RoleGrid>
                  {capitalize(role ?? '') || 'None'}
                  {user && (
                    <ChangeApplicationRoleModal user={user} appRole={role} appId={row.data.id} />
                  )}
                </RoleGrid>
              );
            },
          },
        ]}
        rows={filteredApplications?.map((app) => ({ data: app })) ?? []}
      />
    </>
  );
};

export default OrgMemberApplicationRoles;
