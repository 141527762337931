import { unset } from 'lodash';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import AppValuesLink from '@src/components/shared/AppValuesLink';
import SharedValuesTable from '@src/components/shared/SharedValuesTable/SharedValuesTable';
import { getOutdatedValues } from '@src/containers/Orgs/Apps/containers/App/containers/ViewApplication/components/SharedAppValues/shared-app-values-utils';
import useEnvironmentQuery from '@src/hooks/react-query/environments/queries/useEnvironmentQuery';
import useEnvValuesCreateMutation from '@src/hooks/react-query/shared-values/mutations/useEnvValuesCreateMutation';
import useEnvValuesDeleteMutation from '@src/hooks/react-query/shared-values/mutations/useEnvValuesDeleteMutation';
import useEnvValuesUpdateMutation from '@src/hooks/react-query/shared-values/mutations/useEnvValuesUpdateMutation';
import useAppValuesQuery from '@src/hooks/react-query/shared-values/queries/useAppValuesQuery';
import useEnvValuesQuery from '@src/hooks/react-query/shared-values/queries/useEnvValuesQuery';
import useSharedAppValuesHistoryQuery from '@src/hooks/react-query/shared-values/queries/useSharedAppValuesHistoryQuery';
import { AppValue } from '@src/models/app-value';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const EnvValues = () => {
  // i18n
  const { t } = useTranslation();
  const envSettingsTranslations = t('ENVIRONMENT_SETTINGS');

  // react query
  const { data: appValues = [] } = useAppValuesQuery();
  const { data: envValues = [] } = useEnvValuesQuery();
  const { responseData: envValueSets = [] } = useSharedAppValuesHistoryQuery(true);
  const { data: environment } = useEnvironmentQuery();
  const { mutate: createEnvValue } = useEnvValuesCreateMutation();
  const { mutate: updateEnvValue } = useEnvValuesUpdateMutation();
  const { mutate: deleteEnvValue } = useEnvValuesDeleteMutation();

  const outdatedValues = useMemo(
    () => environment && getOutdatedValues(environment, envValueSets),
    [environment, envValueSets]
  );
  const handleResetConfirmationAction = (envValueKey: string) => {
    deleteEnvValue({ envValueKey });
  };

  const overrideValue = (updatedValue: AppValue, oldEnvValue: AppValue) => {
    const payload = {
      value: updatedValue?.value,
      description: updatedValue.description,
      is_secret: updatedValue.is_secret,
      key: updatedValue.key,
    };

    if (updatedValue?.value !== '' || payload.description !== '') {
      if (oldEnvValue.source === 'app') {
        createEnvValue({ envValue: payload });
      } else if (oldEnvValue.source === 'env') {
        unset(payload, 'is_secret');
        updateEnvValue({ envValue: payload });
      }
    }
  };

  const getKeyValue = (appValue: AppValue) =>
    envValues.find((e) => e.key === appValue.key) || appValue;

  return (
    <>
      <Wrapper>
        <AppValuesLink />
        <SharedValuesTable
          values={appValues?.map(getKeyValue)}
          caption={envSettingsTranslations.OVERRIDES}
          outdatedValues={outdatedValues}
          onUpdate={(newValue, oldValue) => overrideValue(newValue, getKeyValue(oldValue))}
          onResetConfirmation={handleResetConfirmationAction}
          isEnvValues
        />
      </Wrapper>
    </>
  );
};

export default EnvValues;
