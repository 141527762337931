import { TabGroup } from '@humanitec/ui-components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

import useUserDetailQuery from '@src/hooks/react-query/user/useUserDetailQuery';
import { AllRoles } from '@src/models/role';
import { PageContainer } from '@src/styles/layout/PageContainer';
import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { capitalize } from '@src/utilities/string-utility';

import OrgRoleModal from '../../components/role-modals/OrgRoleModal/OrgRoleModal';

const Container = styled(PageContainer)`
  display: flex;
  flex-direction: column;
  margin-top: ${units.margin.xl};
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 50%;
  grid-gap: ${units.padding.md};
`;

const MenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const InfoGroup = ({ label, value }: { label: string; value: string | AllRoles | undefined }) => (
  <div className={'flex flex-column mb-md'}>
    <span className={'txt-translucent txt-sm pb-xs'}>{label}</span>
    <span className={'pb-md txt-md'}>{value}</span>
  </div>
);

const OrgMemberDetails = () => {
  // i18n
  const { t } = useTranslation('orgMembers');

  // React Query
  const { data: userDetail } = useUserDetailQuery();

  return (
    <Container>
      <MenuWrapper>
        <div className={'flex flex-column'}>
          <span className={'txt-translucent txt-sm pb-xs'}>{t('USER')}</span>
          <span className={'txt-xl mb-xl'}>{userDetail?.name || '...'}</span>
        </div>
        {userDetail && (
          <OrgRoleModal mode={{ name: 'change', user: userDetail, buttonVariant: 'main' }} />
        )}
      </MenuWrapper>
      <InfoGroup label={t('EMAIL_ADDRESS')} value={userDetail?.email} />
      <DetailsGrid>
        <InfoGroup
          label={t('STATUS')}
          value={userDetail?.type === 'user' ? t('ACTIVE') : t('INVITATION_PENDING')}
        />
        <InfoGroup
          label={t('JOINED')}
          value={formatDate(userDetail?.created_at, DATE_FORMATS_TYPES.DATE_MONTH_YEAR_HOUR_MINUTE)}
        />
        <InfoGroup label={t('ORGANIZATION_ROLE')} value={capitalize(userDetail?.role ?? '')} />
      </DetailsGrid>
      <TabGroup
        options={[
          {
            label: t('APPLICATIONS_TITLE'),
            value: 'applications',
            link: { to: 'applications' },
          },
          {
            label: t('ENVIRONMENT_TYPES_TITLE'),
            value: 'environment-types',
            link: { to: 'environment-types' },
          },
          {
            label: t('REMOVE_USER_TITLE'),
            value: 'remove',
            link: { to: 'remove' },
          },
        ]}
      />
      <Outlet />
    </Container>
  );
};

export default OrgMemberDetails;
