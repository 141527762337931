import { CardStyles, Icon, WalCard, WarningSection } from '@humanitec/ui-components';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Status from '@src/components/shared/Status';
import useDeploymentErrorsQuery from '@src/hooks/react-query/environments/queries/useDeploymentErrorsQuery';
import useEnvironmentPauseStatusQuery from '@src/hooks/react-query/environments/queries/useEnvironmentPauseStatusQuery';
import useEnvironmentRuntimeQuery from '@src/hooks/react-query/environments/queries/useEnvironmentRuntimeQuery';
import useCheckLatestValueSetDeployed from '@src/hooks/useCheckLatestValueSetDeployed';
import { useDecision } from '@src/hooks/useDecision';
import { DeploymentObject } from '@src/models/deployment-object';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import { generateAppURL, generateDeploymentURL } from '@src/utilities/navigation';

const EnvCard = styled(WalCard)`
  flex-direction: column;
  margin-bottom: ${units.margin.md};
`;
const EnvCardRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const PausedBadge = styled.span`
  display: flex;
  align-items: center;
`;

interface EnvironmentCardProps {
  appId: string;
  envId: string;
  environmentType: string;
  cardStyle?: CardStyles;
  lastDeploy?: DeploymentObject;
  showStatus?: boolean;
  /** if true value set outdated warning will be shown on the card */
  showValuesOutdatedWarning?: boolean;
}

const EnvironmentCard = ({
  appId,
  envId,
  environmentType,
  cardStyle,
  lastDeploy,
  showStatus = false,
  showValuesOutdatedWarning = false,
}: EnvironmentCardProps) => {
  // Router hooks
  const { orgId } = useParams<keyof MatchParams>() as MatchParams;

  // i18n
  const { t } = useTranslation();
  const { t: tViewApplicationList } = useTranslation('viewApplicationList');
  const uiTranslations = t('UI');
  const envCardTranslations = tViewApplicationList('ENVIRONMENT_CARD');

  const { data: envPauseStatusMap } = useEnvironmentPauseStatusQuery({ appId }, showStatus);

  // React Query
  const { data: environmentRuntimeData } = useEnvironmentRuntimeQuery(showStatus, { appId, envId });
  const { environmentRuntime } = environmentRuntimeData || {};
  const { data: lastDeploymentErrors } = useDeploymentErrorsQuery(
    lastDeploy?.id,
    showStatus && lastDeploy?.status === 'failed',
    { appId, envId }
  );
  const { valueSetDeployedIsOutdated } = useCheckLatestValueSetDeployed(
    { appId, envId },
    showValuesOutdatedWarning
  );

  // Optimizely
  const [envStatusDecision] = useDecision('env-card-status');

  const hasRunningPods = Boolean(Object.keys(environmentRuntime?.modules || {}).length);

  const deploymentErrorsCount = lastDeploymentErrors?.length || 0;

  // Needs clarification
  const runtimeErrorsCount = Object.values(environmentRuntime?.modules || {})?.filter(
    (workloadRuntime) => workloadRuntime?.status_class === 'Failure'
  ).length;

  const hasError = deploymentErrorsCount > 0 || runtimeErrorsCount > 0;

  const isEnvironmentPaused = envPauseStatusMap?.[envId];

  const getStatusText = () => {
    let statusText = '';
    if (deploymentErrorsCount > 0) {
      statusText =
        statusText +
        `${deploymentErrorsCount} ${
          deploymentErrorsCount === 1
            ? envCardTranslations.DEPLOYMENT_ERROR
            : envCardTranslations.DEPLOYMENT_ERRORS
        }`;
    }
    if (runtimeErrorsCount > 0) {
      statusText =
        statusText +
        `${deploymentErrorsCount > 0 ? ', ' : ''}${runtimeErrorsCount} ${
          runtimeErrorsCount === 1
            ? envCardTranslations.RUNTIME_ERROR
            : envCardTranslations.RUNTIME_ERRORS
        }`;
    }
    return statusText;
  };
  return (
    <EnvCard cardStyle={cardStyle} key={envId} dataTestId={'environment-card'}>
      <EnvCardRow>
        <div>
          <Link to={generateAppURL(orgId, appId, envId)} className={'title-link'}>
            {envId}
          </Link>
          <span className={'txt-sm txt-translucent'}>{environmentType}</span>
        </div>
        {showStatus &&
          (isEnvironmentPaused ? (
            <PausedBadge>
              <Icon
                name={'pause-in-circle'}
                overrideColor={'yellow'}
                marginRight={'sm'}
                size={14}
              />
              <span className={'txt-xs'}>{uiTranslations.PAUSED}</span>
            </PausedBadge>
          ) : (
            showStatus &&
            lastDeploy &&
            hasRunningPods &&
            envStatusDecision.enabled && (
              <Status
                color={hasError ? 'red' : 'green'}
                link={
                  lastDeploy &&
                  generateDeploymentURL(orgId, appId, envId, lastDeploy?.id, 'workloads')
                }
                text={hasError ? getStatusText() : envCardTranslations.RUNNING}
              />
            )
          ))}
      </EnvCardRow>
      <EnvCardRow className={'mt-sm'}>
        {valueSetDeployedIsOutdated &&
          lastDeploy &&
          !isEnvironmentPaused &&
          showValuesOutdatedWarning && (
            <WarningSection disableDefaultMargin className={'mt-md'}>
              {envCardTranslations.VALUES_OUTDATED}
            </WarningSection>
          )}
      </EnvCardRow>
    </EnvCard>
  );
};

export default EnvironmentCard;
