import { IconSizes, QuickDelete } from '@humanitec/ui-components';
import { cloneDeep } from 'lodash';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';

import { ResourceDependency } from '@src/components/shared/AddResourceDependency/AddResourceDependency';
import ResourceIcon from '@src/containers/Orgs/Resources/components/ResourceIcon/ResourceIcon';
import useDeploymentDeltaUpdateMutation from '@src/hooks/react-query/deployment-delta/mutations/useDeploymentDeltaUpdateMutation';
import useDeploymentSetQuery from '@src/hooks/react-query/deployment-set/useDeploymentSetQuery';
import { useDeltaUtils } from '@src/hooks/useDeltaUtils/useDeltaUtils';
import { DeploymentDelta } from '@src/models/deployment-delta';
import { DeploymentSet, Workload } from '@src/models/deployment-set';
import { ResourceTypes } from '@src/models/resources';
import { MatchParams } from '@src/models/routing';
import { units } from '@src/styles/variables';
import {
  checkPathExistsInDeploymentSet,
  createSharedDeploymentDraftPatchValue,
  findResourceAssociatedRules,
  findResourceAssociatedVolumeMounts,
  patchDeltaToRemoveResourceAssociatedItems,
} from '@src/utilities/deployment-delta-utils';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SubText = styled.div`
  color: ${({ theme }) => theme.color.textTranslucent};
  font-size: ${units.fontSize.sm};
`;

const TitleWrapper = styled.div`
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-column-gap: ${units.margin.md};
  align-items: center;
`;

const ButtonWrapper = styled.div`
  display: flex;
`;

const getSharedResourceAssociated = (
  deploymentSet: DeploymentSet | undefined,
  resource: ResourceDependency & { path: string },
  workloads: Record<string, Workload> | undefined
) => {
  const existsInSet =
    deploymentSet &&
    checkPathExistsInDeploymentSet(`/shared/${resource.path}`, cloneDeep(deploymentSet));
  const deltaToPatch: Partial<DeploymentDelta>[] = [];

  if (workloads) {
    // remove the resource's associated items from each worload
    Object.entries(workloads).forEach(([workloadId, workload]) => {
      const associatedRules = findResourceAssociatedRules(resource.id, workload);
      const associatedVolumeMounts = findResourceAssociatedVolumeMounts(
        resource.path,
        'shared',
        workload
      );

      patchDeltaToRemoveResourceAssociatedItems(
        deltaToPatch,
        workloadId,
        workload,
        associatedRules,
        associatedVolumeMounts
      );
    });
  }

  deltaToPatch.push(
    createSharedDeploymentDraftPatchValue(
      resource.path,
      existsInSet ? {} : { scope: 'delta' },
      'remove'
    )
  );

  return deltaToPatch;
};

interface ResourceCardHeaderContentProps {
  resourceId?: string;
  resourceType?: ResourceTypes;
  resourceClass?: string;
  draftModeActive: boolean;
  resourceDependency?: (ResourceDependency & { path: string }) | undefined;
  scope: 'private' | 'shared';
  iconSize?: IconSizes;
}
const ResourceCardHeaderContent = ({
  resourceId,
  resourceType,
  resourceClass,
  draftModeActive,
  resourceDependency,
  scope,
  iconSize,
}: ResourceCardHeaderContentProps) => {
  // Router hooks
  const { orgId, appId, envId, deltaId } = useParams<keyof MatchParams>() as MatchParams;
  // React Query
  const { mutate: updateDeploymentDelta } = useDeploymentDeltaUpdateMutation();
  const { data: deploymentSet } = useDeploymentSetQuery();

  const { workloads } = useDeltaUtils<Workload>();
  const { updateWorkload } = useDeltaUtils(resourceDependency?.path);

  const handleDeleteClick = () => {
    if (!resourceDependency) return;
    if (scope === 'private') {
      updateWorkload([{ op: 'remove' }]);
    } else if (scope === 'shared') {
      updateDeploymentDelta({
        orgId,
        appId,
        envId,
        deploymentDelta: getSharedResourceAssociated(deploymentSet, resourceDependency, workloads),
        deltaId,
      });
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        {resourceType && <ResourceIcon type={resourceType} size={iconSize} />}
        <div>
          {resourceId && <span data-testid={'active-resource-card-title'}>{resourceId}</span>}
          {resourceType && (
            <SubText data-testid={'active-resource-card-type'}>type: {resourceType}</SubText>
          )}
          <SubText data-testid={'active-resource-card-class'}>
            class: {resourceClass || 'default'}
          </SubText>
        </div>
      </TitleWrapper>
      <ButtonWrapper>
        {draftModeActive && (
          <QuickDelete variant={'secondary'} size={'small'} onConfirm={handleDeleteClick} />
        )}
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ResourceCardHeaderContent;
