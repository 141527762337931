import { rem } from 'polished';
import { useState } from 'react';
import { FormProvider, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components/macro';

import { units } from '@src/styles/variables';
import { DATE_FORMATS_TYPES, formatDate } from '@src/utilities/datetime/datetime';
import { useWalhallForm } from '@src/utilities/form';

import { Button } from '../../base/Button/Button';
import { DatePicker } from '../../base/DatePicker/DatePicker';
import { Text } from '../../base/Text/Text';
import ComboSelect, { ComboSelectMenuItem } from '../ComboSelect/ComboSelect';
import WalDropdownMenu, { DropdownItem } from '../Menu/DropdownMenu/DropdownMenu';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${units.margin.md} 0;
`;

const ConditionWrapper = styled.div`
  display: grid;
  grid-template-columns: ${rem(150)} ${rem(60)} 1fr max-content;
  column-gap: ${units.margin.md};
  align-items: center;
  margin-bottom: ${units.margin.md};
`;

const DatePickerWrapper = styled(DatePicker)`
  flex: 1;
`;

export interface FilterByOption extends DropdownItem<string> {
  /** the comboselect autocomplete options for each filter by item */
  comboSelectOptions?: ComboSelectMenuItem[];
  isDateRange?: boolean;
}

export interface FilterCondition {
  filterBy: string;
  /** the selected filter values in the combo select for the filter condition */
  values?: string[];
  dateRange?: {
    from: string;
    to: string;
  };
}

interface MultiFilterConditionsProps {
  /** a unique id for the filter conditions section  */
  sectionId: string;
  /** the dropwdowm items of the possible options to filter by */
  filterByOptions: FilterByOption[];
  /** the heading of the filter  */
  title?: string;
  /** define the default conditions of the filter */
  defaultConditions?: FilterCondition[];
  /** callback function that will be called whenever the filter conidtions change */
  onFilterChange?: (conditions: FilterCondition[]) => void;
}

export const MultiFilterConditions = ({
  title,
  filterByOptions,
  sectionId,
  defaultConditions,
  onFilterChange,
}: MultiFilterConditionsProps) => {
  // State
  const [conditions, setConditions] = useState<FilterCondition[]>(defaultConditions || []);

  // Form
  const methods = useWalhallForm({
    values: {
      [sectionId]: conditions.map((condition) => ({
        filterBy: condition.filterBy,
        values: condition.values,
        dateRange: condition.dateRange,
      })),
    },
  });
  const { watch, control } = methods;

  const { fields } = useFieldArray({
    control,
    name: sectionId,
  });

  // i18n
  const { t } = useTranslation();
  const translations = t('UI');

  const selectedFilterByOptions = conditions.map((condition) => condition.filterBy);
  const newFilterByOptions = [...filterByOptions];
  newFilterByOptions.forEach((filterByOption) => {
    filterByOption.hideFromList = selectedFilterByOptions.includes(filterByOption.value);
  });

  const handleAddConditionClick = () => {
    setConditions((prevState) => {
      const newConditions = [...prevState];
      newConditions.push({
        filterBy: filterByOptions.filter(
          (option) => !selectedFilterByOptions.includes(option.value)
        )[0].value,
      });
      return newConditions;
    });
  };

  const handleConditionValuesChange = (items: string[], index: number) => {
    setConditions((prevState) => {
      const newConditions = JSON.parse(JSON.stringify(prevState));
      newConditions[index].values = items;
      if (onFilterChange) {
        onFilterChange(newConditions);
      }
      return newConditions;
    });
  };

  const handleConditionDateChange = (date: string, fromOrToDate: 'from' | 'to', index: number) => {
    setConditions((prevState) => {
      const newConditions = JSON.parse(JSON.stringify(prevState));
      if (!newConditions[index].dateRange) {
        newConditions[index].dateRange = {};
      }
      newConditions[index].dateRange[fromOrToDate] = date;
      if (onFilterChange) {
        onFilterChange(newConditions);
      }
      return newConditions;
    });
  };

  const handleConditionFilterByChange = (id: string, item: DropdownItem<string>, index: number) => {
    setConditions((prevState) => {
      // stringfying and parsing the previous state to assign a deep copy of the conditions
      const newConditions = JSON.parse(JSON.stringify(prevState));
      newConditions[index].filterBy = item.value;
      if (onFilterChange) {
        onFilterChange(newConditions);
      }
      return newConditions;
    });
  };

  const deleteCondition = (index: number) => {
    setConditions((prevState) => {
      const newConditions = [...prevState];
      newConditions.splice(index, 1);
      if (onFilterChange) {
        onFilterChange(newConditions);
      }
      return newConditions;
    });
  };

  return (
    <Wrapper>
      <FormProvider {...methods}>
        <Text size={'sm'} className={'mb-sm'}>
          {title || translations.FILTER_BY}
        </Text>
        {selectedFilterByOptions.length < filterByOptions.length && (
          <div className={'flex-centered'}>
            <Button variant={'borderless-blue'} iconLeft={'plus'} onClick={handleAddConditionClick}>
              {translations.ADD_A_CONDITION}
            </Button>
          </div>
        )}
        <div className={'flex-column'}>
          {fields.map((condition, index) => {
            const filterBy = condition.filterBy;
            const filterByOption = filterByOptions.find((option) => option.id === filterBy);

            const comboSelectItems = filterByOption?.comboSelectOptions || [];

            return (
              <ConditionWrapper key={condition.filterBy}>
                <WalDropdownMenu
                  name={`${sectionId}.${index}.filterBy`}
                  items={newFilterByOptions}
                  onItemClick={(id: string, item: DropdownItem<string>) =>
                    handleConditionFilterByChange(id, item, index)
                  }
                  disabled={conditions.length === filterByOptions.length}
                  fullWidth
                />
                <Text color={'textTranslucent'} size={'sm'}>
                  {filterByOption?.isDateRange ? translations.IS_BETWEEN : translations.IS_ONE_OF}
                </Text>
                {filterByOption?.isDateRange ? (
                  <div className={'flex-centered'}>
                    <DatePickerWrapper
                      name={`${sectionId}.${index}.dateRange.from`}
                      dataTestId={`${sectionId}-from-date`}
                      defaultValue={condition.dateRange?.from}
                      maxDate={formatDate(
                        watch(`${sectionId}.${index}.dateRange.to`),
                        DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY
                      )}
                      onChange={(value) => handleConditionDateChange(value, 'from', index)}
                    />
                    <Text color={'textTranslucent'} size={'sm'} className={'mx-md'}>
                      {translations.AND}
                    </Text>
                    <DatePickerWrapper
                      name={`${sectionId}-to-date`}
                      defaultValue={
                        condition.dateRange?.to ||
                        formatDate(
                          new Date().toString(),
                          DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY
                        )
                      }
                      minDate={formatDate(
                        watch(`${sectionId}.${index}.dateRange.from`),
                        DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY
                      )}
                      maxDate={formatDate(
                        new Date().toString(),
                        DATE_FORMATS_TYPES.HYPHENATED_YEAR_MONTH_DAY
                      )}
                      dataTestId={`${sectionId}-to-date`}
                      onChange={(value) => handleConditionDateChange(value, 'to', index)}
                    />
                  </div>
                ) : (
                  <ComboSelect
                    items={comboSelectItems}
                    defaultValues={condition.values}
                    name={`${sectionId}-condition-values-${index}`}
                    onChange={(items) => handleConditionValuesChange(items, index)}
                    maxHeight={200}
                    addCustomChips
                    hideLabel
                  />
                )}
                <Button
                  variant={'secondary'}
                  iconLeft={'delete'}
                  ariaLabel={translations.DELETE_FILTER_CONDITION}
                  onClick={() => deleteCondition(index)}
                  size={'medium'}
                />
              </ConditionWrapper>
            );
          })}
        </div>
      </FormProvider>
    </Wrapper>
  );
};

export default MultiFilterConditions;
