import { KeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { KeyValue } from '@src/models/general';
import { SecretStore } from '@src/models/secret-store';
import { cl, st } from '@src/styles/global-styles';
import { KeyBindings } from '@src/types/key-bindings';
import { useWalhallForm } from '@src/utilities/form';

import { Button } from '../../base/Button/Button';
import { WalInput } from '../../base/Input/Input';
import { WalLabel } from '../../base/Label';
import SecretValue from '../../base/SecretValue/SecretValue';
import { Toggle } from '../../base/Toggle/Toggle';
import { AutoCompleteOptions } from '../AutoCompleteInput/AutoCompleteInput';
import WalDropdownMenu from '../Menu/DropdownMenu/DropdownMenu';

export interface VariablesViewAddEditProps {
  /* unique id */
  id?: string;
  /* key value object */
  keyValue?: KeyValue;
  autoCompleteOptions?: AutoCompleteOptions;
  /* method called when the save button is clicked */
  onSave?: (formData: any) => void;
  /* shows an indicator that the Save button was pressed */
  isSaving?: boolean;
  /* method called when the cancel button is clicked */
  onCancel?: () => void;
  /* toogles whether to show the description field */
  showDescription?: boolean;
  /* toogles whether to show the secret field */
  showSecret?: boolean;
  /* disables users from editting the key field */
  disableKeyField?: boolean;
  /* disables users from editting the description field */
  disableDescriptionField?: boolean;
  /* switches to one of 3 possible mode */
  mode?: 'add' | 'edit' | 'view';
  /* list of existing keys to validate against because you can"t addduplicate variable keys */
  existingKeys?: string[];
  /* Make value a required field (ex: in case of creating override)*/
  isValueFieldRequired?: boolean;
  isSecretReference?: boolean;
  secretStores?: SecretStore[];
}

const FieldInput = styled(WalInput)<{ emphasizePlaceholder?: boolean }>`
  textarea,
  input {
    ${st('my-sm')}
  }
  ${({ emphasizePlaceholder: emphasisPlaceholder, theme }) =>
    emphasisPlaceholder &&
    ` textarea::placeholder {
      color: ${theme.color.text}
    }`}
`;

export const VariablesViewAddEdit = ({
  id,
  keyValue,
  autoCompleteOptions,
  onSave,
  isSaving = false,
  onCancel,
  showDescription,
  showSecret = false,
  disableKeyField = false,
  disableDescriptionField = false,
  mode = 'view',
  existingKeys,
  isValueFieldRequired: requiredValue = false,
  isSecretReference,
  secretStores,
}: VariablesViewAddEditProps) => {
  const [focusOnValue, setFocusOnValue] = useState(false);
  const valueInputRef = useRef<HTMLInputElement>(null);
  const keyInputRef = useRef<HTMLInputElement>(null);

  const secretStoreDropdownItems =
    secretStores
      ?.filter((secretStore) => secretStore.id !== 'humanitec')
      .map((secretStore) => ({
        id: secretStore.id,
        label: secretStore.id,
        value: secretStore.id,
      })) || [];

  // i18n
  const { t } = useTranslation();
  const uiTranslations = t('UI');
  // constants
  const isViewMode = mode === 'view';

  const methods = useWalhallForm<KeyValue>({
    defaultValues: {
      key: '',
      value: '',
      description: '',
      is_secret: false,
      secret_store_id: isSecretReference ? secretStoreDropdownItems[0]?.id : undefined,
      secret_version: isSecretReference ? '' : undefined,
      secret_key: isSecretReference ? '' : undefined,
    },
  });
  const {
    trigger,
    watch,
    handleSubmit,
    reset,
    setValue,
    getValues,
    formState: { isDirty, dirtyFields },
  } = methods;

  const formValues = watch();

  useEffect(() => {
    // Focus on key input initially
    keyInputRef.current?.focus();
  }, []);

  useEffect(() => {
    // Set initial state of Toggle
    setValue('is_secret', Boolean(keyValue?.is_secret));
  }, [keyValue?.is_secret, setValue]);

  const handleConfirm = useCallback(() => {
    getValues().value = getValues().value?.replace(/\\n/g, '\n'); // unescape newlines
    trigger().then((valid) => {
      const updatedValues = getValues();
      if (valid) {
        if (mode === 'add') {
          const payload = isSecretReference
            ? {
                key: updatedValues.key,
                description: updatedValues.description,
                secret_ref: {
                  store: updatedValues.secret_store_id,
                  ref: updatedValues.secret_key,
                  version: updatedValues.secret_version,
                },
                is_secret: true,
              }
            : updatedValues;
          onSave?.(payload);
          reset();
        }
        if (mode === 'edit') {
          onSave?.({
            // In case of secret value, the defaultValue is ''.We need to avoid using it during form submission unless user
            // specifically changes the value. So  logic is :if ( secret and value is not changed) , do not pass value for form submission
            ...(updatedValues.is_secret && !dirtyFields.value
              ? {}
              : {
                  value: updatedValues.value,
                }),
            key: updatedValues.key,
            description: updatedValues.description,
            index: keyValue?.index,
            secret_ref: isSecretReference
              ? {
                  store: updatedValues.secret_store_id,
                  ref: updatedValues.secret_key,
                  version: updatedValues.secret_version,
                }
              : undefined,
            is_secret: updatedValues.is_secret,
          });
          onCancel?.();
        }
      }
    });
  }, [
    onSave,
    trigger,
    keyValue?.index,
    dirtyFields,
    mode,
    reset,
    onCancel,
    isSecretReference,
    getValues,
  ]);

  const handleCancel = () => {
    onCancel?.();
  };

  const onKeyDownForKeyField = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER) {
      event?.preventDefault();
      if (autoCompleteOptions) {
        setFocusOnValue(true);
      } else {
        valueInputRef.current?.focus();
      }
    }
  };

  const onKeyDownForValueField = (event: KeyboardEvent) => {
    if (event.keyCode === KeyBindings.ENTER && !event.shiftKey) {
      trigger().then((valid) => {
        if (valid) {
          handleConfirm();
        }
      });
      keyInputRef.current?.focus();
    }
  };

  const renderValueField = isViewMode ? (
    keyValue?.is_secret ? (
      <div className={'mb-md'}>
        <WalLabel>{uiTranslations.VALUE_LABEL}</WalLabel>
        <SecretValue secretVersion={keyValue.secret_version} />
      </div>
    ) : (
      <FieldInput
        name={'value'}
        inputRef={valueInputRef}
        labelAbove
        required={requiredValue}
        label={uiTranslations.VALUE_LABEL}
        viewMode={isViewMode}
        emphasizePlaceholder={keyValue?.is_secret && !requiredValue}
        defaultValue={!keyValue?.is_secret ? keyValue?.value : ''}
      />
    )
  ) : (
    <>
      <FieldInput
        name={'value'}
        inputRef={valueInputRef}
        labelAbove
        rows={5}
        required={requiredValue}
        label={uiTranslations.VALUE_LABEL}
        viewMode={isViewMode}
        defaultValue={!keyValue?.is_secret ? keyValue?.value : ''}
        onKeyDown={onKeyDownForValueField}
        autoCompleteOptions={autoCompleteOptions}
        focusOnInputState={[focusOnValue, setFocusOnValue]}
        emphasizePlaceholder={keyValue?.is_secret && !requiredValue}
        isMonacoEditor
      />
      {!isViewMode && (
        <div {...cl('txt-sm', 'mb-lg', 'mt-md', 'txt-translucent')}>
          {uiTranslations.TYPE_TO_ADD}{' '}
          <a
            href={'https://developer.humanitec.com/platform-orchestrator/reference/placeholders'}
            target={'_blank'}
            rel={'noreferrer'}>
            {uiTranslations.PLACEHOLDERS}
          </a>
        </div>
      )}
    </>
  );

  const renderSecretField =
    // show an editable toggle only in add mode
    mode === 'add' ? (
      <>
        <div {...cl('txt-sm', 'my-sm')} id={'secret-toggle'}>
          {uiTranslations.SECRET}
        </div>
        <div {...cl('flex')} role={'group'} aria-labelledby={'secret-toggle'}>
          <Toggle name={'is_secret'} defaultChecked={keyValue?.is_secret} />
          {formValues.is_secret && (
            <span className={'txt-sm'}>{uiTranslations.THIS_VARIABLE_IS_A_SECRET}</span>
          )}
        </div>
      </>
    ) : (
      // show a disabled toggle only in edit and view mode if the value is a secret
      keyValue?.is_secret && (
        <>
          <div {...cl('txt-sm', 'my-sm')} id={'secret-toggle'}>
            {uiTranslations.SECRET}
          </div>
          <div {...cl('flex')} role={'group'} aria-labelledby={'secret-toggle'}>
            <Toggle name={'is_secret'} defaultChecked={keyValue?.is_secret} disabled />
            <span className={'txt-sm'}>{uiTranslations.THIS_VARIABLE_IS_A_SECRET}</span>
          </div>
        </>
      )
    );

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(handleConfirm)} key={id}>
        <FieldInput
          labelAbove
          name={'key'}
          inputRef={keyInputRef}
          label={uiTranslations.KEY_LABEL}
          placeholder={uiTranslations.KEY_LABEL}
          readonly={disableKeyField}
          viewMode={isViewMode}
          defaultValue={keyValue?.key}
          required
          standardValidation={[{ type: 'key' }, { type: 'existingId', ids: existingKeys || [] }]}
          onKeyDown={onKeyDownForKeyField}
          onMouseDown={(e) => {
            e.stopPropagation();
            setFocusOnValue(false);
          }}
        />
        {!isSecretReference ? (
          renderValueField
        ) : (
          <>
            {isViewMode ? (
              <div className={'flex-column mb-md'}>
                <WalLabel>{uiTranslations.SECRET_STORE}</WalLabel>
                <span>{keyValue?.secret_store_id || '-'}</span>
              </div>
            ) : (
              <WalDropdownMenu
                className={'mb-md'}
                label={uiTranslations.SECRET_STORE}
                name={'secret_store_id'}
                items={secretStoreDropdownItems}
                defaultValue={keyValue?.secret_store_id || secretStoreDropdownItems[0]?.id}
              />
            )}
            <WalInput
              name={'secret_key'}
              labelAbove
              label={uiTranslations.REFERENCE}
              placeholder={uiTranslations.REFERENCE}
              className={'mb-md'}
              defaultValue={keyValue?.secret_key}
              viewMode={isViewMode}
              required
            />
            <WalInput
              name={'secret_version'}
              labelAbove
              label={uiTranslations.REFERENCE_VERSION}
              viewMode={isViewMode}
              className={'mb-md'}
              defaultValue={keyValue?.secret_version}
              placeholder={uiTranslations.REFERENCE_VERSION}
            />
          </>
        )}
        {showDescription && (
          <>
            <FieldInput
              name={'description'}
              labelAbove
              rows={5}
              label={uiTranslations.DESCRIPTION}
              placeholder={uiTranslations.DESCRIPTION}
              readonly={disableDescriptionField}
              viewMode={isViewMode}
              defaultValue={keyValue?.description}
            />
          </>
        )}
        {!isSecretReference && showSecret && !isViewMode && renderSecretField}
        {!isViewMode && (
          <div {...cl('mt-xl', 'flex')}>
            {isSaving ? (
              <Button {...cl('mr-sm')} variant={'primary'} type={'submit'} disabled loading>
                {uiTranslations.SAVING}...
              </Button>
            ) : (
              <Button {...cl('mr-sm')} variant={'primary'} type={'submit'} disabled={!isDirty}>
                {uiTranslations.SAVE}
              </Button>
            )}
            <Button variant={'secondary'} onClick={handleCancel}>
              {uiTranslations.CANCEL}
            </Button>
          </div>
        )}
      </form>
    </FormProvider>
  );
};

export default VariablesViewAddEdit;
