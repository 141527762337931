/* eslint-disable no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-function */
import {
  SecretValue,
  Text,
  VariablesViewAddEditProps,
  WalTable,
  WalTableColumn,
  WalTableRow,
} from '@humanitec/ui-components';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import useDeploymentQuery from '@src/hooks/react-query/environments/queries/useDeploymentQuery';
import useGetEnvValueSetVersionQuery from '@src/hooks/react-query/shared-values/queries/useGetEnvValueSetVersionQuery';
import { KeyValue } from '@src/models/general';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DeployedValues = () => {
  // Component state
  const [expandedRow, setExpandedRow] = useState<number | undefined>();

  // i18n
  const { t } = useTranslation();

  const translations = t('VIEW_DEPLOYED_VALUES');
  const uiTranslations = t('UI');

  // React Query
  const { data: displayedDeployment } = useDeploymentQuery();
  const { data } = useGetEnvValueSetVersionQuery(displayedDeployment?.value_set_version_id);

  const sources = {
    app: translations.SOURCES.APP,
    env: translations.SOURCES.ENV,
  };

  const columns: WalTableColumn<KeyValue & { source: string }>[] = [
    {
      label: uiTranslations.KEY_LABEL,
      prop: 'key',
      alignIconAndText: true,
      template: (row) => <Text size={'base'}>{row.data.key}</Text>,
      justifyContent: 'flex-start',
      ellipsisTooltip: {
        maxWidth: 380,
        maxCharacters: 35,
        text: (row) => row.data.key,
      },
    },
    {
      label: uiTranslations.VALUE_LABEL,
      prop: 'value',
      template: (row) =>
        row.data.is_secret ? (
          <SecretValue secretVersion={row.data.secret_version} />
        ) : (
          <Text size={'base'}>{row.data.value}</Text>
        ),
      justifyContent: 'flex-start',
      ellipsisTooltip: {
        maxWidth: 380,
        maxCharacters: 35,
        text: (row) => {
          return row.data.is_secret && row.data.secret_version
            ? row.data.secret_version
            : row.data.value;
        },
        showGenericText: true,
      },
    },
    {
      label: 'Source',
      prop: 'source',
      template: (row) => (
        <Text size={'base'}>{sources[row.data.source as keyof typeof sources]}</Text>
      ),
      justifyContent: 'flex-start',
      ellipsisTooltip: {
        maxWidth: 380,
        maxCharacters: 35,
        text: (row) => sources[row.data.source as keyof typeof sources],
        showGenericText: true,
      },
    },
  ];

  const renderVariablesView = ({
    id,
    keyValue,
  }: VariablesViewAddEditProps & { keyValue: { source: string } }) => (
    <div key={id}>
      <Text color={'textTranslucent'} size={'sm'} className={'mb-sm'}>
        {uiTranslations.KEY_LABEL}
      </Text>
      <br />
      <Text size={'base'} className={'mb-md'}>
        {keyValue.key}
      </Text>
      <br />
      <Text color={'textTranslucent'} size={'sm'} className={'mb-sm'}>
        {uiTranslations.VALUE_LABEL}
      </Text>
      <br />
      <Text size={'base'} className={'mb-md'}>
        {keyValue.is_secret ? (
          <SecretValue secretVersion={keyValue.secret_version} />
        ) : (
          keyValue.value
        )}
      </Text>
      <br />
      <Text color={'textTranslucent' as const} size={'sm'} className={'mb-sm'}>
        {uiTranslations.SOURCE_LABEL}
      </Text>
      <br />

      <Text size={'base'}>{keyValue.source}</Text>
    </div>
  );

  const rowData: WalTableRow[] = data?.values
    ? Object.values(data.values).map(({ key, value, source, is_secret, secret_version }) => ({
        data: { key, value, source, is_secret, secret_version },
        expandableContent: renderVariablesView({
          id: key,
          keyValue: {
            key,
            value,
            source: sources[source],
            is_secret,
            secret_version: secret_version || '',
          },
          mode: 'view',
        }),
      }))
    : [];

  return (
    <Wrapper>
      <Text size={'sm'}>{translations.TAB_DESCRIPTION}</Text>
      <WalTable
        caption={translations.TAB_DESCRIPTION}
        columns={columns}
        expandedRowState={[expandedRow, setExpandedRow]}
        disableScrolling
        tableStyle={'expandable'}
        rows={rowData}
      />
    </Wrapper>
  );
};

export default DeployedValues;
